import React, { ForwardedRef, forwardRef } from 'react';
import track from 'react-tracking';
import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';
import { Card, CardImage, Heading, Modal } from '@xxxlgroup/hydra-ui-components';
import { Product } from 'graphql-types/generated/types-generated';
import useMessage from 'components/Message/useMessage';
import ProductRecommendations from 'components/ProductRecommendations';
import SelectedProduct from 'components/HotspotImage/components/SelectedProduct';
import Layout from 'components/Layout';
import ProductSlider from 'components/ProductSlider';
import CmsProductCellContainer from 'cms/CmsProductCellContainer';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import type { Image as ImageType } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';

import styles from './HotspotImageModal.scss';

const componentType = 'HotspotImageModal';

type HotspotImageModalProps = {
  onCloseModal: () => void;
  products: Product[];
  selectedProductCode: string;
  setSelectedProductCode: React.Dispatch<React.SetStateAction<string | null>>;
  containerRef: React.RefObject<HTMLDivElement>;
};

const HotspotImageModal = forwardRef(
  (props: HotspotImageModalProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { onCloseModal, products, selectedProductCode, setSelectedProductCode, containerRef } =
      props;

    const { variant: expVariant, variants: expVariants } = useExperiment(
      'productRecommendationsTest',
    );

    const tracking = useTracking(props, componentType);

    const hasProductRecommendations = expVariant === expVariants.enabled;

    const [closeBtnText, modalHeading, moreProductsHeading, similarProductsTitle] = useMessage([
      'wxs.modal.closeButton.ariaLabel',
      'poseidon.hotspotImage.modal.heading',
      'poseidon.hotspotImage.modal.more.products',
      'poseidon.hotspotImage.modal.similar.products',
    ]);

    const selectProduct = (event: React.MouseEvent, productCode: string) => {
      setSelectedProductCode(productCode);
      const selectedProduct = products.find((product) => product.code === productCode);

      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      tracking(event, { productCode, product: selectedProduct });
    };

    const productsFromLook = (
      <ProductSlider
        id={selectedProductCode}
        heading={<Heading content={moreProductsHeading} level={3} />}
        headingTitle={moreProductsHeading}
      >
        {products.map(({ code, mediaData, name }) => {
          if (!code || code === selectedProductCode) {
            return false;
          }

          const images = mediaData?.presentation?.filter((media) => media?.fileType === 'IMAGE');
          const mainImage = images?.[0];

          return (
            <Card
              className={styles.productCard}
              data-purpose="hotspotImageModal.productCard"
              hasBorder
              key={code}
            >
              {mainImage && (
                <CardImage className={styles.productCardImage} source={mainImage as ImageType} />
              )}
              <button
                aria-label={name ?? undefined}
                className={styles.productCardBtn}
                data-purpose="hotspotImageModal.productCard.btn"
                onClick={(event) => selectProduct(event, code)}
              />
            </Card>
          );
        })}
      </ProductSlider>
    );

    const selectedProduct = products.find((product) => product.code === selectedProductCode);

    return (
      <Modal
        i18n={{ close: closeBtnText }}
        onClose={onCloseModal}
        ref={ref}
        variant="sidebar"
        heading={modalHeading}
      >
        <div ref={containerRef}>
          <Layout className={styles.layout} variant="full">
            {selectedProduct && <SelectedProduct selectedProduct={selectedProduct} />}
          </Layout>
          <Layout className={styles.layout} variant="full">
            {productsFromLook}
          </Layout>
          <Layout variant="full">
            {hasProductRecommendations ? (
              <ProductRecommendations productCode={selectedProductCode} />
            ) : (
              <CmsProductCellContainer
                id={selectedProductCode}
                productCode={selectedProductCode}
                productProvider="AI_VISUAL_RECOS"
                title={similarProductsTitle}
              />
            )}
          </Layout>
        </div>
      </Modal>
    );
  },
);

export default track(tagComponent(componentType), { forwardRef: true })(HotspotImageModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Types } from '@xxxlgroup/hydra-ui-components';
import HotspotImage from 'components/HotspotImage';
import { PRODUCTS_QUERY } from 'components/HotspotImage/Products.query';
import { useQuery } from '@apollo/client';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';

const CmsHotspotImageComponent = ({ hotspotImage, hotspotDataList, codes }) => {
  const hasCodes = codes?.trim() !== '';
  const { loading, data, error } = useQuery(PRODUCTS_QUERY, {
    variables: { codes },
    skip: !hasCodes,
  });

  if (!loading && !error && data?.getProducts?.products) {
    const {
      getProducts: { products },
    } = data;
    return (
      <HotspotImage
        hotspotImage={hotspotImage}
        hotspotDataList={hotspotDataList}
        products={products}
      />
    );
  }
  return null;
};

CmsHotspotImageComponent.propTypes = {
  /** List of product codes */
  codes: PropTypes.string.isRequired,
  /** Array with coordinates (posX, posY) and productcode */
  hotspotDataList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Main image of component */
  hotspotImage: Types.image.isRequired,
};

export default WithSmartEdit(CmsHotspotImageComponent);

import React from 'react';
import { Button } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import type { Product } from 'graphql-types/generated/types-generated';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';

import useMessage from 'components/Message/useMessage';
import EntryCompound from 'components/Entries/EntryCompound';
import EnergyLabel from 'components/EnergyLabel';
import Attributes from 'components/Entries/EntryCompound/components/Attributes';
import EntryImage from 'components/Entries/EntryCompound/components/Image';
import Name from 'components/Entries/EntryCompound/components/Name';
import Price from 'components/Entries/EntryCompound/components/Price';
import EcoTax from 'components/Entries/EntryCompound/components/EcoTax';
import Link from 'components/WebshopLink';
import ToggleFavouriteButton from 'modules/favourites/components/ToggleFavouriteButton';

import styles from './SelectedProduct.scss';

type SelectedProductProps = {
  selectedProduct: Product;
};

const SelectedProduct = ({ selectedProduct }: SelectedProductProps) => {
  const { additionalProductExpenses, code, energyEfficiencyData, mediaData, url } = selectedProduct;

  const isFavouritesEnabled = useFeatureFlagsEnabled('poseidon.favourites');
  const detailsBtnText = useMessage('poseidon.hotspotImage.modal.details.btn');

  const priceAndTaxInformation = (
    <>
      <Price alignment="left" />
      <EcoTax additionalProductExpenses={additionalProductExpenses} className={styles.ecoTax} />
    </>
  );

  return (
    <>
      <EntryCompound className={styles.wrapper} product={selectedProduct}>
        <EntryImage className={styles.image} />
        <div className={styles.details}>
          <div>
            <Name className={styles.name} size="medium" />
            <Attributes withDisabledKeys className={styles.attributes} size="small" />
            {energyEfficiencyData && mediaData && (
              <EnergyLabel
                className={styles.energyLabel}
                classNameLink={styles.energyLink}
                energyEfficiencyData={energyEfficiencyData}
                mediaData={mediaData}
              />
            )}
          </div>
          {priceAndTaxInformation}
        </div>
      </EntryCompound>
      <div className={styles.buttonsWrapper}>
        {isFavouritesEnabled && code && (
          <ToggleFavouriteButton className={styles.favouriteButton} code={code} />
        )}
        <Link
          data-purpose="hotspotImage.selectedProduct.details.btn"
          href={url}
          as={<Button glyphAfter={arrowRight} layout="block" variant="primary" />}
        >
          {detailsBtnText}
        </Link>
      </div>
    </>
  );
};

export default SelectedProduct;

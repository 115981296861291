import React, { useCallback, useEffect, useRef, useState } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import { Button, IconButton, Image } from '@xxxlgroup/hydra-ui-components';
import { config } from '@xxxlgroup/hydra-config';
import { priceTag, visibilityOff } from '@xxxlgroup/hydra-icons';
import type { HotspotImageProps } from 'components/HotspotImage/HotspotImage.types';
import useMessage from 'components/Message/useMessage';
import HotspotImageModal from 'components/HotspotImage/components/HotspotImageModal';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';

import styles from 'components/HotspotImage/HotspotImage.scss';

const MODAL_DEFAULT_HEIGHT_DESKTOP = '100%';

const componentType = 'HotspotImage';

const HotspotImage = (props: HotspotImageProps) => {
  const { hotspotImage, hotspotDataList, products } = props;

  const [isModalActive, setIsModalActive] = useState(false);
  const [isHotspotHidden, setIsHotspotHidden] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState<string | null>(null);

  const tracking = useTracking(props, componentType);

  const [hotspotImageInfoText, hideHotspotsBtnText] = useMessage([
    'poseidon.hotspotImage.products.btn',
    'poseidon.hotspotImage.hideHotspots.btn',
  ]);

  const modalRef = useRef<HTMLDivElement>(null);
  const hotspotImageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (!isModalActive || !modalRef.current || !hotspotImageRef.current) return;

    if (window.innerWidth >= config.breakpoints.md) {
      modalRef.current.style.height = MODAL_DEFAULT_HEIGHT_DESKTOP;
      return;
    }

    const remainingViewportHeight = window.innerHeight - hotspotImageRef.current.clientHeight;

    modalRef.current.style.height = `${remainingViewportHeight}px`;
  }, [isModalActive]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    requestAnimationFrame(() => {
      handleResize();
    });
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const toggleHotspots = (event: React.MouseEvent) => {
    setIsHotspotHidden(!isHotspotHidden);
    tracking(event, { isHotspotHidden: !isHotspotHidden });
  };

  const handleHotspotClick = (event: React.MouseEvent, productCode: string) => {
    (event.currentTarget as HTMLButtonElement).blur();
    setIsModalActive(true);
    setSelectedProductCode(productCode);

    const selectedProduct = products.find((product) => product.code === productCode);

    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    tracking(event, { productCode, product: selectedProduct });
  };

  const handleCloseModal = () => {
    setIsModalActive(false);
    setSelectedProductCode(null);
  };

  const isRenderingHotspots = hotspotDataList.length > 0 && !isHotspotHidden;

  const renderHotspots = () =>
    hotspotDataList.map((hotspot) => {
      const { positionX, positionY, productCode } = hotspot;
      const positionStyling = {
        top: `${positionY}%`,
        left: `${positionX}%`,
      };

      const product = products.find((product) => product.code === productCode);
      const ariaLabel = product?.name ?? `hotspot - ${productCode}`;

      const isSelected = productCode === selectedProductCode;

      return (
        <li key={productCode} style={positionStyling} className={styles.hotspotListItem}>
          <IconButton
            aria-label={ariaLabel}
            className={classnames(styles.hotspot, {
              [styles.isSelected]: isSelected,
            })}
            data-purpose="hotspotImage.hotspot"
            glyph={priceTag}
            onClick={(event) => handleHotspotClick(event, productCode)}
            title={ariaLabel}
          />
        </li>
      );
    });

  return (
    <>
      <div
        className={classnames(styles.hotspotImageWrapper, {
          [styles.isModalActive]: isModalActive,
        })}
        data-testid="hotspotImage"
        ref={hotspotImageRef}
      >
        <Image
          key={hotspotImage.hashCode}
          source={hotspotImage.source}
          className={styles.hotspotImage}
          useSrcSet
          sizes={`(min-width: ${config.breakpoints.lg}px) ${config.breakpoints.xl}px,
              (min-width: ${config.breakpoints.sm}px) ${config.breakpoints.lg}px,
              100vw`}
        />
        {isRenderingHotspots && <ul className={styles.hotspots}>{renderHotspots()}</ul>}
        {!isHotspotHidden && (
          <IconButton
            aria-hidden
            className={styles.hideHotspotsBtn}
            data-purpose="hotspotImage.hideHotspot.iconBtn"
            glyph={visibilityOff}
            onClick={toggleHotspots}
            title={hideHotspotsBtnText}
          />
        )}
        <Button
          aria-hidden
          className={classnames(styles.toggleBtn, {
            [styles.isHidden]: !isHotspotHidden,
          })}
          data-purpose="hotspotImage.hideHotspot.btn"
          glyphAfter={isHotspotHidden ? priceTag : visibilityOff}
          onClick={toggleHotspots}
          tabIndex={-1}
          title={isHotspotHidden ? hotspotImageInfoText : undefined}
        >
          {isHotspotHidden ? hotspotDataList?.length : hideHotspotsBtnText}
        </Button>
      </div>
      {isModalActive && selectedProductCode && (
        <HotspotImageModal
          onCloseModal={handleCloseModal}
          products={products}
          ref={modalRef}
          selectedProductCode={selectedProductCode}
          setSelectedProductCode={setSelectedProductCode}
          containerRef={containerRef}
        />
      )}
    </>
  );
};

export default track(tagComponent(componentType))(HotspotImage);

import React from 'react';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@xxxlgroup/hydra-ui-components';
import RepresentationSlider from 'cms/CmsProductCellContainer/components/RepresentationSlider';
import { AI_RECOMMENDATIONS_QUERY } from 'cms/CmsProductCellContainer/CmsProductCellContainer.query';
import useMessage from 'components/Message/useMessage';
import styles from 'components/ProductRecommendations/ProductRecommendations.scss';

interface ProductRecommendationsProps {
  productCode: string;
}

const ProductRecommendations = (props: ProductRecommendationsProps) => {
  const { productCode } = props;
  const sliderTitle = useMessage('poseidon.ai.product.recommendations');
  const { loading, error, data } = useQuery(AI_RECOMMENDATIONS_QUERY, {
    variables: {
      productCode,
      recommendationsParams: { type: 'product' },
    },
  });

  if (loading) {
    return <Skeleton className={styles.skeleton} count={1} />;
  }

  if (error || !data) {
    return null;
  }

  // Temporary code for the AB test. Product type will be defined later.
  const parsedProducts = data?.getAIRecommendations?.products.map((product: any) => ({
    ...product,
    recommendationId: 'AIRecommendations_pdp',
    provider: 'AI_PRODUCT_RECOMMENDATIONS',
  }));

  return (
    <RepresentationSlider
      anchorId={sliderTitle}
      id={sliderTitle}
      representationType="SLIDER"
      sliderTitle={sliderTitle}
      products={parsedProducts}
      isHeadingShown
    />
  );
};

export default ProductRecommendations;

import { gql } from '@apollo/client';

import { reducedPriceData, dimensions } from 'graphql-fragments';
import { energyEfficiencyData, mediaData } from 'graphql-fragments/product';

export const PRODUCTS_QUERY = gql`
  query products($codes: String!) {
    getProducts(codes: $codes) {
      products {
        attributes {
          colorMapping {
            code
            targetColor
            webColor
          }
          dimensions {
            ${dimensions}
            strRepresentation
            strRepresentationKey
          }
          material
        }
        name
        code
        description
        teaserText
        url
        brands {
          name
        }
        eyecatchers {
          code
          style
        }
        energyEfficiencyData {
          ${energyEfficiencyData}
        }
        priceData {
          ${reducedPriceData}
          currentPrice {
            specialOfferTypeData {
              numberOfAsterisks
            }
          }
        }
        picture {
          altText
          cdnFilename
        }
        mediaData {
          ${mediaData}
        }
      }
    }
  }
`;
